@import '../../../../../css/variables/colors.scss';

.board-column {
    background-color: rgba(151, 187, 206,0.2);
    height: 85vh;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    flex-direction: column;

    display: -webkit-flex;
    -webkit-flex-direction: column;

    overflow-y:auto;   
}

.board-filter-button {
    margin-top: 15px;
}

.cards-column {
    height: auto;
    display: flex;
    flex-direction: column;

    display: -webkit-flex;
    -webkit-flex-direction: column;

    overflow-y:auto;
}

.board-column-title {
    margin-top: 10px;
    align-self: flex-start; 
    width: 100%;
}

.board-column-title h4{
    padding-left: 20px;
    background-image: linear-gradient(to right, $primary-color 0%, &$light-primary-color 50%, #d1e9b6 100%);
    color: white;
}

.board {
    display: flex;
    margin-top: 10px;
}

.main-card {
    background-color: white;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.main-card:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 $primary-color;
    cursor: pointer;
}

.main-card:hover .tooltip {
    visibility: visible;
}

/* Tooltip text */
.main-card .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}
  

.card-title {
    margin-top: 10px;
    margin-left: 10px;
    margin-block-end: 0.5px;
    font-weight: bold;
}

.card-content {
    margin-left: 8px;
    margin-right: 8px;
    margin-block-start: 0.5px;
    margin-block-end: 0.5px;
    font-size: 14px;
}

.card-button {
    margin-left: 20px;
    margin-block-end: 10px;
    cursor: pointer;
    border-radius: 5px;
}

//FOR CUSTOM SCROLLBAR
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-color; 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $dark-primary-color; 
}
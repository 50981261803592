
.dropdownSearch{
    background-color: white;
}

.error{
    border-color: red;
}
.optionDropdownSearch{
    cursor: pointer;
}

.optionDropdownSearchSelected{
    cursor: pointer;
    color: #666;
    font-weight: bold;
}

.optionDropdownSearch:hover{
    background-color:#1890ff;
    color:white;
}

.optionsContainer{
    background-color:white;
    padding-inline-start:5px; 
    position:absolute;
    color: #666;
    border: 1px solid #e5e5e5;
    padding:5px;
    max-height: 200px;
    overflow-y: auto;
    display: block;
}
@import '../css/variables/colors.scss';

.file-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    //border
    border-style: dashed;
    border-width: 5px;
    border-color: $primary-color;
    border-radius: 10px;
    padding: 20px;
}

.file_upload {
    opacity: 0.0;
 
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
 
    /* IE 5-7 */
    filter: alpha(opacity=0);
  
    /* Netscape or and older firefox browsers */
    -moz-opacity: 0.0;
 
    /* older Safari browsers */
    -khtml-opacity: 0.0;
 
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height:100%;
 }
@import '../../../css/variables/colors.css';

.pair-table-header {
    background: lightgray;
    font-weight: bold;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
}

.pair-table-header-blank {
    background: lightgray;
    font-weight: bold;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
}

.pair-table-header-left {
    color: white;
    background: lightgray;
    font-weight: bold;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
}

.pair-table-space {
    text-align: center;
    cursor: pointer;
}

.pair-table-space:hover {
    text-align: center;
    cursor: pointer;
    background: var(--primary-color);
}

.pair-table {
    border: 5px solid whitesmoke;
    border-collapse: collapse;  
    margin: 0 auto;
    margin-left: 0px !important;  
}

.pair-table-container {
    overflow-x: auto;
    overflow: auto;
    max-height: 80vh;
    max-width:  150vh;
}
@import '../css/variables/colors.css';

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)  {
    background-color: var(--primary-color-transparent) !important; 
}

.ant-btn > .anticon {
    line-height: 0;
}

.ant-modal-mask {
    z-index: 1010;
}

.ant-modal-wrap
{
    z-index: 1020;
}

.ant-switch-checked {
    background-color: var(--primary-color-transparent-medium);
}

.ant-btn-primary{
    background-color: var(--primary-color-transparent-medium);
    border-color: var(--primary-color-transparent-medium);
}

.ant-btn-primary:hover {
    color: #fff;
    background-color: var(--dark-primary-color-transparent);
    border-color: var(--dark-primary-color-transparent);
}

.ant-btn-primary > span {
    color: #fff;
}

.ant-btn-sm:hover {
    color: var(--dark-primary-color-transparent);
    border-color: var(--dark-primary-color-transparent);
}

.ant-table-row:hover{
    cursor: pointer;
    background-color: var(--primary-color-transparent);
}

.div-message-container{
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
}
.div-message-container-error{
    padding: 0 10px;
    background: #fff;
    color: #666;
    border: 1px solid red;
  }

.text-area-reply-message{
    height: 100px !important;
    resize: none;
    box-shadow: none !important; 
    width: 100% !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 5px !important;
    box-sizing: content-box !important;
  }

  .text-area-border-top{
    border-top: 1px dashed #d9d9d9 !important;
  }
  
  .text-area-reply-message:focus {
    outline: none;
    background-color: #fff;
    color: #666;
    border-color: #1e87f0;
  }
@import '../../css/variables/colors.scss';

.dashboard-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: 2px;
}

.dashboard-card-frame {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.showcase { 
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    white-space:nowrap;
} 

.showcase-button {
    display:inline-block;
    width: auto;
    //margin-right: 30px;
}

//FOR CUSTOM SCROLLBAR
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-color; 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $dark-primary-color; 
}
body {
  background: #f3f1f2;
}

.app {
  margin: 1rem auto;
  max-width: 40rem;
}

.app .ql-container {
  min-height: 50em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-editor {
  min-height: 400px !important;
}

.ql-container {
  min-height: 400px !important;
}

.app .ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/*
.ql-picker.ql-insertParameters {
  width: 200px;
}

.ql-picker.ql-insertParameters .ql-picker-item::before, 
.ql-picker.ql-insertParameters .ql-picker-label::before {
  content: 'Select a parameter...'
}

.ql-picker.ql-insertParameters .ql-picker-item:before {
  content: attr(data-label);
}

.ql-custom .ql-picker-item:before {
  content: attr(data-label);
}
*/
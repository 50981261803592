@import './css/variables/colors.scss';

.App {
  background-color: #9E9E9E;
  min-height: 100vh;
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #448AFF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Materialize CSS INPUT COLORS */
/* blue color = blue accent-2 */
/* label color */
.input-field label {
  color: #BDBDBD !important;
}

/* label focus color */
.input-field input[type=text]:focus+label {
  color: $primary-color !important;
}

/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid $primary-color !important;
  box-shadow: 0 1px 0 0 $primary-color !important;
}

/* label focus color */
.input-field input[type=password]:focus+label {
  color: $primary-color!important;
}

/* label underline focus color */
.input-field input[type=password]:focus {
  border-bottom: 1px solid $primary-color !important;
  box-shadow: 0 1px 0 0 $primary-color !important;
}

/* valid color */
.input-field input[type=text].valid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}

/* invalid color */
.input-field input[type=text].invalid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}

/* icon prefix focus color */
.input-field .prefix.active {
  color: #000 !important;
}

/* MATERIALIZE CSS COLOR FOR WAVES */

.waves-effect.waves-blue .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: rgba(68, 138, 255, 0.65);
}

.waves-effect.waves-e2e-green .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: rgba(139, 195, 74, 0.65);
}


/* UI KIT CSS MODIFIERS */

.uk-table td {
  padding: 5px 12px;
}

.uk-table th {
  //background: #7395AE !important;
  background-image: linear-gradient(to bottom, #AAD187 0%, #c9e6af 50%, #deecd0 100%);
  color: black !important;
}

.uk-card-badge {
  background: $primary-color !important;
}

/*
 * Button Green
 */
.uk-button-green {
  background-color: $primary-color !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}

/* Hover + Focus */
.uk-button-green:hover,
.uk-button-green:focus {
  background-color: $dark-primary-color !important;
  color: #fff !important;
}

/* OnClick + Active */
.uk-button-green:active,
.uk-button-green.uk-active {
  background-color: $primary-color !important;
  color: #fff !important;
}

.uk-button-green:disabled {
  background-color: transparent;
  color: #999 !important;
  border-color: #e5e5e5 !important;
}

.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 30px;
}

.uk-paginator-input {
  height: 25px;
  width: 50px;
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  overflow: visible;
  /* 3 */
  border: 0 none;
  /* 4 */
  margin-top: 5px;
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

/* Focus INPUT, SELECT, TEXTAREA */
.uk-input:focus,
.uk-paginator-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #666;
  border-color: $primary-color;
}

.uk-spinner {
  color: $primary-color
}

.uk-button {
  text-transform: capitalize !important;
  border-radius: 10px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  min-width: 120px !important;
}

.min-width-0 {
  min-width: 0px !important;
}

.e2e {
  background-color: #ffffff;
}

/* UK TAB Active */
.uk-tab>.uk-active>a {
  color: #333;
  border-color: $primary-color;
}

/* checkbox and radio button */

.uk-checkbox {
  margin-right: 15px;
  // margin-left: 15px;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  outline: none !important;
  border-color: $primary-color !important;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: $primary-color !important;
  border-color: transparent !important;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #6e9c3a !important;
}

.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
}

.table-container {
  margin-top: 10px;
}

.task-property-border {
  border: 1px solid #e5e5e5 !important;
}

.border-table {
  border-left: 1px solid #e5e5e5 !important;
  border-right: 1px solid #e5e5e5 !important;
  margin-bottom: 10px;
}

.uk-table {
  border-top: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.uk-table td:not(:first-child) {
  border-left: 1px solid #e5e5e5;
}

.uk-table tr:not(:first-child) {
  border-left: 0 !important;
}

.uk-table th:not(:first-child) {
  border-left: 1px solid #e5e5e5;
}

.uk-table-divider>tr:not(:first-child),
.uk-table-divider> :not(:first-child)>tr,
.uk-table-divider> :first-child>tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/* Colors for Striped Tables */
.uk-table-striped>tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

/*
 * Table Hover
 */
.uk-table-hover>tr:hover,
.uk-table-hover tbody tr:hover {
  background: $primary-color-transparent;
}



/* Pagination items */
.uk-pagination-number {
  /* 1 */
  display: block;
  /* 2 */
  color: #999;
  transition: color 0.1s ease-in-out;
}

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination>*>* {
  /* 1 */
  display: block;
  /* 2 */
  color: #999;
  transition: color 0.1s ease-in-out;
}

/* Hover + Focus */
.uk-pagination>*> :hover,
.uk-pagination>*> :focus {
  color: $dark-primary-color;
  text-decoration: none;
}

/* Active */
.uk-pagination>.uk-active>* {
  color: $primary-color;
  font-weight: bold;
}

/* Disabled */
.uk-pagination>.uk-disabled>* {
  color: #999;
}


/* FOR LABELS */
.uk-form-label {
  color: #333;
  font-weight: bold;
  font-size: 0.750rem;
}



.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 1px;
  margin-top: 1px;
}

.uk-form-label-properties-task {
  color: #333;
  font-size: 0.750rem;
}

@media (max-width: 959px) {

  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label-properties-task {
    display: block;
    margin-bottom: 5px;
  }
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label-properties-task {
    width: 200px;
    margin-top: 7px;
    float: left;
  }
}

/* buttons */





/* HEADINGS*/

/* Headings
 ========================================================================== */
h1,
.uk-h1,
h2,
.uk-h2,
h3,
.uk-h3,
h4,
.uk-h4,
h5,
.uk-h5,
h6,
.uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 10px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #333;
  text-transform: none;
}


/* For day picker */
.DayPickerInput-OverlayWrapper {
  overflow-y: visible !important;
  margin-top: 0px !important;
  z-index: 1000 !important;
}

/* number inputs*/
.input-number {
  text-align: right !important;
}

.loading-box {
  margin: 20px;
}

fieldset {
  margin: 0 0 0 0 !important;
}

.uk-button-min-font {
  font-size: 0.875rem !important;
}

.grid-button {
  line-height: 25px;
}

.property-box {
  margin-bottom: 10px;
  margin-left: 10px;
  outline: 1px solid #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.uk-width-1-12 {
  width: calc(100% * 1 / 12);
}

.uk-width-2-12 {
  width: calc(100% * 2 / 12);
}

.uk-width-3-12 {
  width: calc(100% * 3 / 12);
}

.uk-width-4-12 {
  width: calc(100% * 4 / 12);
}

.uk-width-5-12 {
  width: calc(100% * 5 / 12);
}

.uk-width-6-12 {
  width: calc(100% * 6 / 12);
}

.uk-width-7-12 {
  width: calc(100% * 7 / 12);
}

.uk-width-8-12 {
  width: calc(100% * 8 / 12);
}

.uk-width-9-12 {
  width: calc(100% * 9 / 12);
}

.uk-width-10-12 {
  width: calc(100% * 10 / 12);
}

.uk-width-11-12 {
  width: calc(100% * 11 / 12);
}

.uk-width-12-12 {
  width: 100%;
}

.uk-width-1-7 {
  width: calc(100% * 1 / 7.001);
}

.uk-width-2-7 {
  width: calc(100% * 2 / 7.001);
}

.uk-width-4-7 {
  width: calc(100% * 4 / 7.001);
}

.uk-width-1-8 {
  width: calc(100% * 1 / 8.001);
}

.uk-width-2-8 {
  width: calc(100% * 2 / 8.001);
}

.uk-width-28 {
  width: 28%;
}

.uk-hr {
  border-top: 1px solid #000000;

}

.uk-hr-vertical {
  border-left: 1px solid #000000;
}


@media (min-width: 720px) {
  .uk-form-horizontal .uk-form-label-properties-task {
    width: 235px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-label-properties-task-filter {
    width: 50px;
    margin-top: 3px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 250px;
  }

  .uk-form-horizontal .uk-form-controls-filter {
    margin-left: 50px;
  }
}

.icon-container-properties {
  padding-left: 0px !important;
}

.container-filter-properties {
  padding-left: 50px !important;
}

.icons-container-properties {
  margin-left: 0px !important;
}

.filter-label {
  padding-left: 0 !important;
}

.warning-color {
  color: red;
}

.uk-divider-vertical {
  height: 100%;
}

button.uk-accordion-title {
  width: 100%;
  text-align: left;
  //background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  //font-weight: bold;
  margin-bottom: 0px;
  border-bottom: 1px solid #e5e5e5;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  //color: #333;
  background-image: linear-gradient(to right, $primary-color 0%, $light-primary-color 50%, #d1e9b6 100%);
  color: white;
}

.uk-accordion-title:focus,
.uk-accordion-title:hover {
  //color: #666;
  text-decoration: none;
  outline: 0;
  background-color: rgb(232, 243, 219);
  color: white;
  cursor: pointer;
}

button.uk-accordion-title:hover {
  //background-color: rgb(232, 243, 219);
  color: white;
  //background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 70%,  #8ec055 100%);
  background-image: linear-gradient(to right, $primary-color 100%, $light-primary-color 50%, #d1e9b6 100%);
  background-position: right center;
}

/*button.uk-accordion-title:focus{
  //background-color: rgb(232, 243, 219);
  color: white;
  //background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 70%,  #8ec055 100%);
  background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 50%, #d1e9b6 100%);
  background-position: right center;
}*/

div.uk-accordion-content {
  padding-left: 20px;
  //align-content: center;
  //border-left: 1px solid #e5e5e5;
  //border-right: 1px solid #e5e5e5;
  //background-color: red;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 15px;
  margin-top: 0px;
}

.uk-accordion-content {
  padding-top: 0px;
}

.uk-accordion> :nth-child(n+2) {
  margin-top: 0px;
}

.uk-accordion-content>:last-child {
  //margin-bottom: 0;
  //margin-top: 0px;
}

.uk-open>.uk-accordion-title {
  //background-color: rgb(232, 243, 219);
  background-image: linear-gradient(to right, $primary-color 100%, $light-primary-color 50%, #d1e9b6 100%);
  color: white;
}

.container-note-message {
  padding-left: 50px !important;
}

input[type=color] {
  width: 100%;
  height: 100%;
  border: none;
  //border-radius: 40px;
  background: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  //border: solid 1px #000; /*change color of the swatch border here*/
  //border-radius: 40px;
  border: none;
}

.uk-grid>.uk-grid-margin {
  margin-top: 10px;
}

.propoerties-tasks-category-title {
  background-image: linear-gradient(to right, $primary-color 0%, $light-primary-color 50%, #d1e9b6 100%);
  color: white;
  padding-left: 10px;
  margin: 0 0 0 0 !important;
}

.propoerties-tasks-category-title:hover {
  background-image: linear-gradient(to right, $primary-color 100%, $light-primary-color 50%, #d1e9b6 100%);
}

.properties-tasks-category-title-new {
  background-image: linear-gradient(to right, $primary-color 0%, $light-primary-color 50%, #d1e9b6 100%);
  color: white;
  padding-left: 10px;
  display: flex;
  margin: 0 0 0 0 !important;
}

.properties-tasks-category-title-new:hover {
  background-image: linear-gradient(to right, $primary-color 100%, $light-primary-color 50%, #d1e9b6 100%);
}

.properties-tasks-category-image {
  display: block;
  margin-left: auto;
  margin-right: initial;
  color: black;
}

.properties-tasks-category-title-new1 {
  background-image: linear-gradient(to right, #116466 0%, #437c7e 50%, #8eafaf 100%);
  color: white;
  font-size: large;
  line-height: 50px;
  padding-left: 10px;
  height: 50px;
  margin: 0 0 10px 0 !important;
}

.properties-tasks-category-title-new1:hover {
  background-image: linear-gradient(to right, #116466 100%, #437c7e 50%, #8eafaf 100%);
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.badge {
  position: relative;
  top: -10px;
  right: 11px;
  padding: 2px 4px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #1076af 0%, #00548e 22%, #00548e 100%);
  color: white;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.task-row {
  margin-top: 0px !important;
  padding-top: 20px;
  border-bottom: lightgray solid 0.5px;
}

.task-group {
  margin-left: 75px !important;
}

.task-div {
  background-color: rgb(248, 248, 248) !important;
}

.properties-div {
  background-color: rgb(248, 248, 248) !important;
}

.back-arrow {
  font-size: 1.75em !important;
  //margin-bottom: 5px;
}

.back-arrow-white {
  font-size: 1.75em !important;
  color: #fff;
  margin-bottom: 5px;
}

.component-title {
  padding-left: 0px;
  padding-top: 5px;
  padding-right: 25px;
  padding-bottom: 20px;
}

fieldset {
  border-width: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0em;
  padding-inline-start: 0em;
  padding-inline-end: 0em;
  padding-block-end: 0em;
}

.rw-multiselect-tag {
  margin-top: -3px !important;
}

.pointer {
  cursor: pointer;
}

.input-icon {
  position: relative;
}

.input-icon>i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 45%;
  pointer-events: none;
  width: 40px;
  text-align: center;
  font-style: normal;
  color: #666;
}

.input-icon>input {
  padding-left: 25px;
  padding-right: 0;
}

.input-icon-right>i {
  right: 0;
}

.input-icon-right>input {
  padding-left: 0;
  padding-right: 40px;
  text-align: right;
  color: #666;
}

.title-widget {
  font-weight: bold;
  color: #00548d;
  background-image: linear-gradient(to right, $primary-color 0%, $light-primary-color 50%, #d1e9b6 100%);
  display: block, ;
  text-align: center, ;
  border-radius: 5px
}

.text-like-title-widget {
  font-weight: bold;
  color: #00548d;
  text-align: center, ;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.date-picker-custom {
  position: absolute;
  background-color: white;
  z-index: 99999999;
}

//Online Style -> New Bene Invitation
@mixin boxSizingBorderBox {
  -webkit-box-sizing: border-box; // Safari/Chrome, other WebKit
  -moz-box-sizing: border-box; // Firefox, other Gecko
  box-sizing: border-box; // Opera/IE 8+
}

// boxSizingBorderBox
$colorGray80:#ccc;
$colorBlue2:#195495; 
$colorLightGray2:#cbcbcb;
$colorBlue:#00548d;
$colorGray90:#e5e5e5;
$backgroundTopHeader:'./img/top-background.png';

.inline-content {
  display: inline-flex;
}

.ul-options{
  padding: 10px;
}
.li-options {
  border-color: rgba(255, 255, 255, 0)!important;
}
.li-a-options{
  text-decoration: none;
  border-right: 1px solid #e3e3e3;
  border-color: rgba(255, 255, 255, 0)!important;
  color: #00548d!important;
  padding: 10px;
  outline: none;
}

.li-a-options:focus{
  border-right: 1px solid #e3e3e3;
  background-color: #f1f1f1!important;
}


.wrapper {
  margin: auto;
  width: 100%;
  // min-width:820px;
  padding-left: 20px;
  padding-right: 20px;
  @include boxSizingBorderBox();
}

.header-container h2 {
  float: right;
  display: inline;
  font-size: 1em;
  color: #00548d;
  margin: 0;
  font-weight: normal;
}

.header-container nav {
  margin-top: 50px;
}

.header-container img {
  width: 20%;
  float: left;
}

.main-container {
  header {
    margin-top: 0px;
    padding: 5px 0 0;
    border-bottom: $colorLightGray2 1px solid;
    margin-bottom: 5px;
    color: $colorBlue2 !important;
    font-size: 1.5em;

    h3 {
      margin: 0.3em 0 0.3em;
      color: $colorBlue2 !important;
      font-size: 1em;
    }
  }
}

.bck-default-2 {
  background-color: $colorBlue;
}
.bck-info {background-color:$colorBlue;}
.button_style_smaller {
  // border-radius:5px;
  color: #fefefe;
  width: auto;
  border: none;
  cursor: pointer;
  margin: 5px 5px 5px 0px;
  box-sizing: border-box;
  padding: 10px 40px;
  font-size: 0.75rem;
}
@media only screen and (min-width : 1200px) {
  .wrapper {
    width: 1024px;
  }
}

.header-container,
.header-options {
  position: relative;
  z-index: 1;
}

.clearfix:before,
.clearfix:after {
  content: " "; // 1
  display: table; // 2
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
  clear: both;
}

.footer-container {
  margin-top: 100px;
  min-height: 250px;
  background-color: $colorGray80;
  padding-top: 20px;
}

.txt-min-height {
  min-height: 28px;
}

.txt-a-l {
  text-align: left
}

.txt-a-c {
  margin: 4px 0 4px 1%;
  text-align: center
}

.txt-a-r {
  text-align: right
}

.footer-link {
  color: #00548d;
  white-space: nowrap;
  font-size: .75rem;
  text-decoration: underline;
}

.pad-b-20p {padding-bottom:20px;}
.pad-b-30p {padding-bottom:30px;}
.pad-b-40p {padding-bottom:40px;}
@media only screen and (min-width : 681px) {
	// =grid
	// generator source: http://www.responsivegridsystem.com/calculator/
	// ---------------------------------------------------------------------------
	//  COLUMN SETUP
	.col {
		display: block;
		float:left;
		margin: 4px 0 4px 1%;
        @include boxSizingBorderBox();
	}
  .col > .section > .col {
      margin-top:0;
      margin-bottom:0;
  }
  .col > .section + .section {
    margin-top:4px;
    margin-bottom:4px;
  }    
  //.column-reduce-margin .col {
	//  margin: 4px 0 4px 1%;
  //}

	.col:first-child { margin-left: 0; }

	//  GROUPING
	.group:before,
	.group:after { content:""; display:table; }
	.group:after { clear:both;}
	.group {
    zoom:1; // For IE 6/7
  }

  //  GRID OF ONE
	.span_1_of_1 {width: 100%;}
	//  GRID OF TWO
	.span_2_of_2 {width: 100%;}
	.span_1_of_2 {width: 49.2%;}
	//  GRID OF THREE
	.span_3_of_3 { width: 100%; }
	.span_2_of_3 { width: 66.13%; }
	.span_1_of_3 { width: 32.26%; }
	//  GRID OF FOUR
	.span_4_of_4 {width: 100%;}
	.span_3_of_4 {width: 74.6%;}
	.span_2_of_4 {width: 49.2%;}
	.span_1_of_4 {width: 23.8%;}
	//  GRID OF FIVE
	.span_5_of_5 {width: 100%;}
	.span_4_of_5 {width: 79.68%;}
	.span_3_of_5 {width: 59.36%;}
	.span_2_of_5 {width: 39.04%;}
	.span_1_of_5 {width: 18.72%;}
	//  GRID OF SIX
	.span_6_of_6 {width: 100%;}
	.span_5_of_6 {width: 83.06%;}
	.span_4_of_6 {width: 66.13%;}
	.span_3_of_6 {width: 49.2%;}
	.span_2_of_6 {width: 32.26%;}
	.span_1_of_6 {width: 15.33%;}
	//  GRID OF SEVEN
	.span_7_of_7 {width: 100%;}
	.span_6_of_7 {width: 85.48%;}
	.span_5_of_7 {width: 70.97%;}
	.span_4_of_7 {width: 56.45%;}
	.span_3_of_7 {width: 41.94%;}
	.span_2_of_7 {width: 27.42%;}
	.span_1_of_7 {width: 12.91%;}
	//  GRID OF EIGHT
	.span_8_of_8 {width: 100%;}
	.span_7_of_8 {width: 87.3%;}
	.span_6_of_8 {width: 74.6%;}
	.span_5_of_8 {width: 61.9%;}
	.span_4_of_8 {width: 49.2%;}
	.span_3_of_8 {width: 36.5%;}
	.span_2_of_8 {width: 23.8%;}
	.span_1_of_8 {width: 11.1%;}
	//  GRID OF NINE
	.span_9_of_9 {width: 100%;}
	.span_8_of_9 {width: 88.71%;}
	.span_7_of_9 {width: 77.42%;}
	.span_6_of_9 {width: 66.13%;}
	.span_5_of_9 {width: 54.84%;}
	.span_4_of_9 {width: 43.55%;}
	.span_3_of_9 {width: 32.26%;}
	.span_2_of_9 {width: 20.97%;}
	.span_1_of_9 {width: 9.688%;}
	//  GRID OF TEN
	.span_10_of_10 {width: 100%;}
	.span_9_of_10 {width: 89.84%;}
	.span_8_of_10 {width: 79.68%;}
	.span_7_of_10 {width: 69.52%;}
	.span_6_of_10 {width: 59.36%;}
	.span_5_of_10 {width: 49.2%;}
	.span_4_of_10 {width: 39.04%;}
	.span_3_of_10 {width: 28.88%;}
	.span_2_of_10 {width: 18.72%;}
	.span_1_of_10 {width: 8.56%;}
	//  GRID OF ELEVEN
	.span_11_of_11 {width: 100%;}
	.span_10_of_11 {width: 90.76%;}
	.span_9_of_11 {width: 81.52%;}
	.span_8_of_11 {width: 72.29%;}
	.span_7_of_11 {width: 63.05%;}
	.span_6_of_11 {width: 53.81%;}
	.span_5_of_11 {width: 44.58%;}
	.span_4_of_11 {width: 35.34%;}
	.span_3_of_11 {width: 26.10%;}
	.span_2_of_11 {width: 16.87%;}
	.span_1_of_11 {width: 7.636%;}
	//  GRID OF TWELVE
	.span_12_of_12 {width: 100%;}
	.span_11_of_12 {width: 91.53%;}
	.span_10_of_12 {width: 83.06%;}
	.span_9_of_12 {width: 74.6%;}
	.span_8_of_12 {width: 66.13%;}
	.span_7_of_12 {width: 57.66%;}
	.span_6_of_12 {width: 49.2%;}
	.span_5_of_12 {width: 40.73%;}
	.span_4_of_12 {width: 32.26%;}
	.span_3_of_12 {width: 23.8%;}
	.span_2_of_12 {width: 15.33%;}
	.span_1_of_12 {width: 6.866%;}

	//  OTHER KIND OF GRID
  .col_0_of_V {width:17.25%;margin:3px 0 !important}
  .col_1_of_V {width:82.75%;margin:3px 0 !important}
  .col_0_of_VA {width:23%;margin:3px 0 !important}
  .col_1_of_VA {width:77%;margin:3px 0 !important}
  .col_0_of_VB {width:38.5%;margin:3px 0 !important}
  .col_1_of_VB {width:61.5%;margin:3px 0 !important}

  .col_0_of_U {width:4%;margin:2px 0 !important}
  .col_1_of_U {width:10%;margin:2px 0 !important}
  .hide-issue-date-col-U .col_1_of_U {width:14%;margin:2px 0 !important}
  .col_2_of_U {width:14%;margin:2px 0 !important}
  .col_3_of_U {width:20%;margin:2px 0 !important}
  .hide-issue-date-col-U .col_3_of_U {width:23%;margin:2px 0 !important}
  .col_4_of_U {width:7%;margin:2px 0 !important}
  .col_5_of_U {width:8%;margin:2px 0 !important}
  .col_6_of_U {width:5%;margin:2px 0 !important}
  .col_7_of_U {width:9%;margin:2px 0 !important}
  .col_8_of_U {width:14%;margin:2px 0 !important}
  .col_8_of_U1 {width:5%;margin:2px 0 !important}
  .col_8_of_U2 {width:9%;margin:2px 0 !important}
  .col_9_of_U {width:3%;margin:2px 0 !important}
  .col_8_of_UA {width:20%;margin:2px 0 !important}
  .col_8_of_UA1 {width:5%;margin:2px 0 !important}    
  .col_8_of_UA2 {width:15%;margin:2px 0 !important}
  .col_10_of_U {width:6%;margin:2px 0 !important}
  .col_11_of_U {width:0%;margin:2px 0 !important}

  .col_1_of_T {width:36%;margin:4px 0 !important}
  .col_2_of_T {width:64%;margin:4px 0 !important}

  .col_1_of_SA {width:20%;margin:4px 0 !important}
  .col_1_of_SB {width:29%;margin:4px 0 !important}
  .col_1_of_S {width:49%;margin:4px 0 !important}
  .col_2_of_S {width:29%;margin:4px 0 !important}
  .col_3_of_S {width:22%;margin:4px 0 !important}

  .col_1_of_R {width:30%;margin:4px 0 !important}
  .col_2_of_R {width:16%;margin:4px 0 !important}
  .col_3_of_R {width:20.5%;margin:4px 0 !important}
  .col_4_of_R {width:33.5%;margin:4px 0 !important}

  .col_1_of_Q {width:75.5%;margin:4px 0 !important}
  .col_2_of_Q {width:24.5%;margin:4px 0 !important}

	.col_1_of_P {width:72%;margin:4px 0 !important}
  .col_2_of_P {width:28%;margin:4px 0 !important}

	.col_1_of_O {width:42%;margin:4px 0 !important}
  .col_2_of_O {width:58%;margin:4px 0 !important}

	.col_1_of_N {width:71%;margin:4px 0 !important}
  .col_2_of_N {width:29%;margin:4px 0 !important}

	.col_1_of_M {width:70%;margin:2px 0 !important}
  .col_2_of_M {width:25%;margin:2px 0 !important}
  .col_3_of_M {width:5%;margin:2px 0 !important}

  .col_4_of_L {width:20%;margin:4px 0 !important}
  .col_3_of_L {width:22%;margin:4px 0 !important}
  .col_2_of_L {width:22%;margin:4px 0 !important}
	.col_1_of_L {width:36%;margin:4px 0 !important}

	.col_2_of_K {width:70%;margin:4px 0 !important}
	.col_1_of_K {width:30%;margin:4px 0 !important}

	.col_2_of_J {width:62%;margin:4px 0 !important}
	.col_1_of_J {width:38%;margin:4px 0 !important}

	.col_1_of_I {width:42%;margin:4px 0 !important}
	.col_2_of_I {width:22%;margin:4px 0 !important}
	.col_3_of_I {width:36%;margin:4px 0 !important}

	.col_1_of_H {width:20%;margin:4px 0 !important}
	.col_2_of_H {width:47%;margin:4px 0 !important}
	.col_3_of_H {width:33%;margin:4px 0 !important}

	.col_1_of_G {width:45%;margin:4px 0 !important}
	.col_2_of_G {width:10%;margin:4px 0 !important}
	.col_3_of_G {width:45%;margin:4px 0 !important}

	.col_1_of_F {width:31%;margin:4px 0 !important}
	.col_2_of_F {width:16%;margin:4px 0 !important}
	.col_3_of_F {width:30%;margin:4px 0 !important}
	.col_4_of_F {width:7%;margin:4px 0 !important}
	.col_5_of_F {width:16%;margin:4px 0 !important}

  .col_1_of_E {width:19%;margin:4px 0 !important}
  .col_2_of_E {width:39%;margin:4px 0 !important}
  .col_3_of_E {width:10%;margin:4px 0 !important}
  .col_4_of_E {width:10%;margin:4px 0 !important}
  // .col_5_of_E {width:10%;margin:4px 0 !important}
  .col_6_of_E {width:19%;margin:4px 0 !important}
  .col_6_of_E1 {width:9%;margin:4px 0 !important}
  .col_6_of_E2 {width:10%;margin:4px 0 !important}
  .col_7_of_E {width:3%;margin:4px 0 !important}

	.col_1_of_D {width:36%;margin:4px 0 !important}
	.col_2_of_D {width:17.5%;margin:4px 0 !important}
	.col_3_of_D {width:30%;margin:4px 0 !important}
	.col_4_of_D {width:16.5%;margin:4px 0 !important}

	.col_1_of_C {width:42%;margin:4px 0 !important}
	.col_2_of_C {width:58%;margin:4px 0 !important}

	.col_1_of_B{ width:32.26%;margin:4px 0 !important}
	.col_2_of_B{ width:56.74%;margin:4px 0 !important}
	.col_3_of_B{ width:11%;margin:4px 0 !important}

	.col_1_of_A {width:28%;margin:4px 0 !important}
	.col_2_of_A {width:31%;margin:4px 0 !important}
	.col_3_of_A {width:10%;margin:4px 0 !important}

  .column-reduce-margin {
    .col_1_of_H, .col_2_of_H, .col_3_of_H,
    .col_1_of_O, .col_2_of_O {
      margin-top:3px !important;
      margin-bottom:3px !important;
    }
  } // .column-reduce-margin

	//  GRID OFFSET
  .offset_span_1_of_3 { margin-left: 32.26%;}
  .offset_span_2_of_3 { margin-left: 66.13%;}
  .offset_span_1_of_4 { margin-left: 23.8%;}
  .offset_span_1_of_7 { margin-left: 12.91%;}
  .offset_span_2_of_7 { margin-left: 27.42% !important;}
  .offset_span_3_of_7 { margin-left: 41.94% !important;}
  .offset_span_4_of_7 { margin-left: 56.45% !important;}
  .offset_span_5_of_7 { margin-left: 70.97% !important;}
  .offset_span_6_of_7 { margin-left: 85.48% !important;}
  .offset_span_2_of_8 { margin-left: 23.8% !important;}
  .offset_span_3_of_8 { margin-left: 36.5% !important;}
  .offset_span_4_of_8 { margin-left: 49.2% !important;}
  .offset_span_5_of_8 { margin-left: 61.9% !important;}
  .offset_span_6_of_8 { margin-left: 74.6% !important;}

	//  PADDING WRAPPER
	.pad-col-lr-50p{padding:0 50px 0;}
	.pad-col-lr-50p .col_2_of_H { width: 30%}
	.pad-col-lr-50p .col_3_of_H { width: 50%}

  // =board gap
  .gap-board-md .col {padding:2px;}
  .gap-board-md .col .label-float {padding-left: 2px;}

  .board-reverse-3 .col{ margin: 0.5% 0 0.5% 1%; }

  // Width
  .width-170-md {width:170%;}
  .width-178-md {width:178%;}

} 
@media only screen and (min-width : 768px) {
  .header-container {
    background: transparent url($backgroundTopHeader) repeat-x fixed 0 -22px;
    min-height: 100px;
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.attachment-link {
  color: #00548d;
  white-space: nowrap;
  text-decoration: underline;
}
h5.uk-accordion-title {
  width: 100%;
  text-align: left;
  //background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  //font-weight: bold;
  margin-bottom: 0px;
  border-bottom: 1px solid #e5e5e5;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  //color: #333;
  background-image: linear-gradient(to right, $primary-color 0%, $light-primary-color 50%, #d1e9b6 100%);
  color: white;
}
h5.uk-accordion-title:hover {
  //background-color: rgb(232, 243, 219);
  color: white;
  //background-image: linear-gradient(to right, #8ec055 100%, #a3ce73 70%,  #8ec055 100%);
  background-image: linear-gradient(to right, $primary-color 100%, $light-primary-color 50%, #d1e9b6 100%);
  background-position: right center;
}

.breadcrumb-div{
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
  color: #fff !important;
  background-image: linear-gradient(to right , $breadcrum-color 30%, #b2cede 100%, #cee0ea 100%);
}

.accordion-new-style{
  padding-left: 5px!important;
  padding-top: 5px!important;
  padding-bottom: 5px!important;
  padding-right: 25px!important;
  color: #fff !important;
  background-image: linear-gradient(to right , #89b4cc 30%, #b2cede 100%, #cee0ea 100%)!important;
  font-weight:500!important;
  font-size: medium!important;
  color: #fff !important;
}
.breadcrumb-item{
  font-weight: normal;
  font-size: medium;
  cursor: 'pointer';
}

.breadcrumb-item:hover{
  font-weight: normal;
  font-size: medium;
  cursor: 'pointer';
  color: #fff !important;
}

.breadcrumb-item-bold{
  font-weight:500;
  font-size: medium;
  color: #fff !important;
}

.ql-tooltip {
  left: auto !important;
}

.MuiDialog-paper {
  overflow-y: visible !important; 
}

.react-tel-input .country-list .search{
  padding: 10px 6px 6px 10px !important;
}

.react-tel-input:disabled{
  cursor: default;
  background-color: #F8F8F8
}

.react-tel-input .form-control:disabled{
  cursor: default;
  background-color: #F8F8F8
}

.uk-form-danger, .uk-form-danger:focus {
  color: #f0506e !important;
  border-color: #f0506e !important;
}

.react-tel-input .country-list {
  width: 242px !important;
}
.mg-t-5 {
  margin-top: 5px!important;
}
.h4-deal{
  font-weight: lighter!important;
}

[list]::-webkit-calendar-picker-indicator { 
  display: none !important; 
}

.uk-input[list]:focus, .uk-input[list]:hover {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
}

.uk-input.uk-form-danger[list]:focus, .uk-input.uk-form-danger[list]:hover
{
  background-image: url(./css/custom-icons/down-arrow_red.svg);
  //filter: invert(52%) sepia(91%) saturate(4233%) hue-rotate(324deg) brightness(105%) contrast(88%);
}
.warning-style{
  background-color: red;
  color: white;
  padding: 10px;
}

div.ig-data-grid.igr-data-grid > div > div:nth-child(1) {
  padding-bottom: 10px;
}

div.ig-data-grid.igr-data-grid > div > div:nth-child(2) {
  opacity: 1 !important;
}

.password-icon {
  position: absolute;
  top: 8px;
  padding: 2px 8px;
  right: 8px;
  cursor: pointer;
}

// .ant-menu > .ant-menu-item:hover,
// .ant-menu > .ant-menu-submenu:hover,
// .ant-menu > .ant-menu-item-active,
// .ant-menu> .ant-menu-submenu-active,
// .ant-menu > .ant-menu-item-open,
// .ant-menu> .ant-menu-submenu-open,
// .ant-menu > .ant-menu-item-selected,
// .ant-menu > .ant-menu-submenu-selected,

// /* Menu */
// .ant-menu-light .ant-menu-submenu-title:hover,
// .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
// /* Submenu */
// .ant-menu-item-selected a, .ant-menu-item a:hover, div.ant-menu-submenu-title .ant-menu-title-content a {
//   // color: #6e9c3a !important;
// }

// .ant-menu-inline .ant-menu-item::after {
//   // border-color: #6e9c3a !important;
// }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $primary-color !important;
}

.ant-menu-title-content, .ant-menu-item-selected a, .ant-menu-item a:hover {
  font-weight: 500 !important;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: $primary-color !important;
}

div.ant-menu-submenu-title .ant-menu-title-content a {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff !important;
}

.ant-menu-dark .ant-menu-submenu-title:hover > i {
  opacity: 1 !important;
}

.ant-layout-sider-trigger, .ant-layout-sider {
  background-color: $primary-color !important;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  bottom: 0 !important;
  top: auto !important;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  margin-bottom: 10px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.message-row-not-read {
  background-color: #e4e4e4;
}

.grid-margin-15 {
  margin-top: 15px !important;
}

.green-background {
  background-color: $primary-color !important;
  background-image: none !important;
}